<template>
  <div class="container">
    <RoleCard role="school">
      <template #label>Регистрация на трек для 8-9 классов</template>
      <template #label_img>
        <img src="@/assets/images/reg_school.svg" />
      </template>
      <template #body>
        <div>
          <div class="row mb-l">
            <div class="col-md-8 col-xs-12">
              <div class="typography">
                <p>
                  Здесь семиклассники могут зарегистрироваться на&nbsp;старший
                  трек НТО для 8&minus;11&nbsp;классов. Если вы&nbsp;учитесь
                  в&nbsp;7&nbsp;классе, уверены в&nbsp;своих силах и&nbsp;хотите
                  соревноваться со&nbsp;старшеклассниками, нажмите
                  &laquo;Продолжить&raquo;. Должны вас предупредить&nbsp;&mdash;
                  это будет по-настоящему сложно.
                </p>
                <p v-if="alreadyRegisted">
                  Вы уже зарегистрированы на олимпиаду на трек для 8-9 классов,
                </p>
                <p v-else-if="isParticipant">
                  Вы уже зарегистрированы на олимпиаду, если вы хотите принять
                  участие в треке для 8-9 классов,
                  <router-link
                    to="/support"
                    class="link"
                    >напишите нам</router-link
                  >, мы посмотрим, что можно сделать.
                </p>
                <div v-else-if="isMentor">
                  <p>Вы уже зарегистрированы как наставник олимпиады НТО.</p>
                </div>
                <div v-else>
                  <p>
                    Будьте внимательны: можно участвовать только в&nbsp;одном
                    треке НТО. Вы&nbsp;не&nbsp;сможете участвовать в&nbsp;НТО
                    Junior для 5&minus;7&nbsp;классов, если зарегистрируетесь
                    на&nbsp;старший трек.
                  </p>
                  <p>
                    Если вы&nbsp;оказались здесь по&nbsp;ошибке и&nbsp;хотите
                    участвовать в&nbsp;НТО Junior, начните
                    <router-link
                      to="/"
                      class="link"
                      >с&nbsp;Главной страницы.</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <template v-if="showContinueButton">
            <base-button
              v-if="showContinueButton"
              class="mr-s"
              :loading="pending"
              @click="handleContinue"
              >Продолжить регистрацию</base-button
            >
            <base-button
              theme="reg"
              to="/"
              tag="router-link"
              >На главную</base-button
            >
          </template>
          <template v-else>
            <base-button
              theme="reg"
              to="/"
              tag="router-link"
              >Перейти в личный кабинет</base-button
            >
          </template>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page Страница регистрации учащихся 5-7 классов
 * на старший трек олимпиады НТО (для 8-9 классов)
 */
import RoleCard from "@/components/registration/RoleCard.vue";
import { LOW_SCHOOL_ALIAS } from "@/constants";

export default {
  name: "RegForSeven",
  metaInfo() {
    return {
      title: "Регистрация для учащихся 7-ых классов на старший трек олимпиады.",
    };
  },
  components: {
    RoleCard,
  },
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isParticipant() {
      return this.$store.getters["user/isParticipant"];
    },
    isMentor() {
      return this.$store.getters["user/isMentor"];
    },
    // уже зарегистрирован на этот трек
    alreadyRegisted() {
      const { user } = this;
      if (!user) return false;
      const currentTrack =
        this.$store.state.tracks[user?.participant?.track_id];
      return currentTrack?.alias === LOW_SCHOOL_ALIAS;
    },
    showContinueButton() {
      const { isMentor, isParticipant } = this;
      return !isMentor && !isParticipant;
    },
  },
  created() {
    this.init();
  },
  methods: {
    handleContinue() {
      if (this.user) {
        this.$router.push({
          name: "registration-school",
          query: {
            upper_track: true,
          },
        });
      } else {
        window.location.href = `${this.$store.state.clientApiPrefix}/auth/go?next=/registration/school?upper_track=true`;
      }
    },
    async init() {
      this.pending = true;
      try {
        await this.$store.dispatch("getTracks");
        await this.$store.dispatch("user/getMe");
      } catch (error) {
        // do nothing
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
